body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E2E8EF;
}

body::-webkit-scrollbar {
  /* width of the entire scrollbar */
  width: 9px !important;
  border-radius: 10px;
  background-color: 'transparent';
}

body::-webkit-scrollbar-track {
  /* color of the tracking area  */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  /* creates padding around scroll thumb */
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(117, 117, 121);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  padding: 10px 16px;
  background: #10ac84;
  border: none;
  color: white;
  box-shadow: 0px 0px 0px 0px;
  transition: all 0.3s;
}

button:hover,
button:focus {
  box-shadow: 0px 0px 0px 0px;
}

