.App {
    text-align: center;
    overflow-x: hidden;
}

.searchbar {
    position: absolute;
    border-radius: 0;
    top: 25%;
    left: 3%;
    right: 3%;
}

.App-logo {
    height: 6vmin;
    pointer-events: none;
}

.collapse {
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.main-header {
    background-color: rgb(240, 255, 241);
    max-height: 12vh;
    width: 1%;
    display: flex;
}

.app-header {
    max-height: 4vh;
    flex-direction: row;
    align-items: left;
    justify-content: space-between;
    font-size: calc(8px + 1.5vmin);
}

.app-header-navigation {
    background-color: #007fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: left;
    /* font-size: calc(2vmin); */
}

.app-header-navigation :hover {
    border-bottom: 3px solid white;
    border-bottom-left-radius: 0px;
    padding-right: 10px;
}

.app-header-link {
    padding: 3px;
    text-decoration: none;
    text-align: center;
    background-color: #007fff;
    color: #ffffff;
    /* font-size: small; */
}

.header {
    padding: 5px;
    padding-left: 5px;
    padding-right: 10.2px;
    text-decoration: none;
    text-align: left;
    margin-left: 5px;
    color: black;
    /* background-color: #007fff; */
    color: #ffffff;
    font-size: large;
}

.content {
    min-height: calc(100% - 100px);
    background-color: #ffffff;
}

#Header_NavigationLink {
    text-align: left;
}

.headertitle {
    color: #0068ff;
}

.dialogHeader {
    color: white;
    font-size: 18px !important;
    margin-top: 8px !important;
    font-weight: bold;
}

.activeMenu {
    padding: 10px;
    padding-left: 5px;
    padding-right: 10.2px;
    text-decoration: none;
    text-align: left;
    margin-left: 10px;
    color: black;
    font-size: large;
    background-color: #faf9f8;
    border-radius: 10px;
}

.scrollbox {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
        border-radius: 10px;
        background-color: 'transparent';
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

.on-scrollbar {
    scrollbar-width: thin;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
}

.on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #F5F5F5;
}

.on-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

.on-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgb(117, 117, 121);
}

.search {
    position: relative;
    color: #aaa;
    font-size: 16px;
    display: flex;
    flex-direction: row;
}

.search input {
    border-radius: 50px;
    border: 2px #215176 solid;
    width: 900px;
    height: 53px;
    box-shadow: 0px 1px 4px 1px rgba(103, 128, 159, 1);
    font-size: 20px;
    text-indent: 10px;
    left: 50px;
}

.search input {
    text-indent: 32px;
    left: 50px;
}

.search .fa-search {
    color: #2980b9;
    float: right;
    font-size: 23px;
    position: absolute;
    top: 13px;
    left: 7px;
    width: 30px;
}

.zoom-in-out-box {
    margin-left: 15px;
    margin-right: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.15, 1.15);
    }

    100% {
        transform: scale(1, 1);
    }
}

.close-account-info {
    animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes close-account-info {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}


/* pagination custom css */

.MuiTablePagination-root .MuiTablePagination-toolbar {
    min-height: 45px;
}

.MuiTablePagination-root .MuiTypography-body2,
.MuiTablePagination-root .MuiTablePagination-select {
    font-size: 13px;
}

.MuiTablePagination-root .MuiTablePagination-actions .MuiIconButton-root {
    padding: 8px;
}


/* Snackbar custom css */

.snackBarStyle .MuiAlert-root {
    margin-top: -20px;
    padding: 2px 13px !important;
    font-size: 13px;
}

.snackBarStyle1.MuiAlert-root {
    font-size: 13px;
    padding: 0px 15px !important;
}

.alertStyle {
    font-size: 13px;
}

.loader,
.loader:after {
    border-radius: 80%;
    width: 25px;
    height: 25px;
}

.loader {
    font-size: 7px;
    position: relative;
    border-top: 0.5em solid rgba(18, 126, 226, 0.2);
    border-right: 0.5em solid rgba(18, 126, 226, 0.2);
    border-bottom: 0.5em solid rgba(18, 126, 226, 0.2);
    border-left: 0.5em solid #127ee2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader2,
.loader2:after {
    border-radius: 80%;
    width: 24px;
    height: 24px;
}

.loader2 {
    font-size: 7px;
    position: relative;
    border-top: 0.5em solid rgba(18, 126, 226, 0.2);
    border-right: 0.5em solid rgba(18, 126, 226, 0.2);
    border-bottom: 0.5em solid rgba(18, 126, 226, 0.2);
    border-left: 0.5em solid #127ee2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* Archive File View */

.loader3,
.loader3:after {
    border-radius: 80%;
    width: 18px;
    height: 18px;
}

.loader3 {
    font-size: 7px;
    position: relative;
    border-top: 0.5em solid rgba(18, 126, 226, 0.2);
    border-right: 0.5em solid rgba(18, 126, 226, 0.2);
    border-bottom: 0.5em solid rgba(18, 126, 226, 0.2);
    border-left: 0.5em solid #127ee2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* ToolTip with Menus */

.dropdown ul {
    position: absolute;
    margin: 20px 0 0 0;
    padding: 20px 0px;
    width: 220px;
    left: 83%;
    box-sizing: border-box;
    z-index: 1;
    border: 1px solid gray;
    background: white;
    border-radius: 6px;
    list-style: none;
}

.dropdown ul li {
    padding: 0;
    margin: 0;
}

.dropdown ul li a:link,
.dropdown ul li a:visited {
    display: inline-block;
    padding: 10px 0.8rem;
    width: 100%;
    box-sizing: border-box;
    color: black;
    text-decoration: none;
}

.dropdown ul li a:hover {
    background-color: lightgray;
    color: black;
}


/* Dropdown triangle */

.dropdown ul::before {
    content: " ";
    position: absolute;
    width: 50;
    height: 50;
    top: -10px;
    left: 83%;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent gray transparent;
}


/* Dashboard */

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    gap: 2rem;
    margin: 2.6rem 1.5vw -30px 1.5vw;
    padding: 0;
    list-style-type: none;
}


/* Ripple effect */

.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:hover {
    background: #c8facd radial-gradient(circle, transparent 1%, #c8facd 1%) center/15000%;
}

.ripple:active {
    background-color: #c8facd;
    background-size: 100%;
    transition: background 0s;
}


/* Div's Responsive */

.divStyle {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin: 0rem 0vw;
    padding: 0;
    list-style-type: none;
}

.linkStyle {
    font-size: 16px;
    color: blue;
    text-decoration: 'none';
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

.zoom-in-box {
    cursor: pointer;
    animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

.footer {
    margin-Top: 15px;
    float: right;
    margin-Right: 50px;
}

.pagination {
    float: right;
    margin-right: 100px;
}


/* Side bar Navigation */

.wrapper {
    display: flex;
    position: relative;
}

.wrapper .sidebar {
    width: 220px;
    height: 100%;
    background: #faf9f8;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: #215176;
    margin-top: 0px;
    text-align: center;
}

.sidebar .row {
    width: 100%;
    height: 60px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: flex-start;
    align-items: center;
}

.sidebar .row:hover {
    cursor: pointer;
    background-color: #243d50;
}

.sidebar #active {
    background-color: #243d50;
}

.row #icon {
    flex: 25%;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 75%;
    padding-left: 10px;
    font-weight: 800;
    display: flex;
    justify-content: left;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-height: 500px) {
    .wrapper .sidebar {
        width: 180px;
        height: 100%;
    }

    .wrapper .sidebar img {
        justify-content: center;
        margin-bottom: 5px;
        padding: 0px 10px;
        margin-left: 10px;
        width: 150px;
        height: 40px;
        background-color: #faf9f8;
    }
}

.wrapper .main_content {
    width: 100%;
    margin-left: 200px;
    color: black;
}

.wrapper .main_content .header {
    padding: 11px 0px 11px 25px;
    background-color: white;
    color: #717171;
    /* border-bottom: 1px solid #e0e4e8; */
}

.required {
    color: red;
}

.line-break {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
    border-bottom-width: thin;
    border-style: dashed;
}

.line-break-logout {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    /* border-top: 2px dashed rgba(145, 158, 171, 0.24); */
    border-color: rgba(145, 158, 171, 0.24);
    border-bottom-width: thin;
    border-style: dashed;
}

.container {
    position: relative;
    border-radius: 5px;
    /* background-color: #f2f2f2; */
    padding: 5px 0 0px 0;
}

/* Two-column layout */
.col {
    float: left;
    width: 50%;
    margin: auto;
    padding: 0 20px;
    margin-top: 6px;
}

.col2 {
    float: left;
    width: 100%;
    margin: auto;
    padding: 10px 20px 0px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* vertical line */
.vl {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    border: 2px solid #ddd;
    height: 95px;
    top: 10px;
}

/* text inside the vertical line */
.vl-innertext {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 5px 7px;
}

/* hide some text on medium and large screens */
.hide-md-lg {
    display: none;
}

/* bottom container */
.bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
}

/* Responsive layout - when the screen is less than 650px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 650px) {
    .col {
        width: 100%;
        margin-top: 0;
    }

    /* hide the vertical line */
    .vl {
        display: none;
    }

    /* show the hidden text on small screens */
    .hide-md-lg {
        display: block;
        text-align: center;
    }
}

/* Login or signup design css */
.line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 77px;
}

div.hr-left,
div.hr-right {
    width: 80%;
    height: 1px;
    background-color: #cccccc;
}

p.line-text {
    font-size: 14px;
    color: #cccccc;
    width: 40%;
    margin: auto 5px;
}

.blink {
    text-align: center;
    line-height: 50px;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

/* Placement Finish*/
.line-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 0px 20px; */
}

div.hr-left1,
div.hr-right1 {
    width: 47%;
    /* height: 0.2px; */
    /* background-color: #cccccc; */
    border: 1px solid lightgray
}

p.line-text1 {
    font-size: 14px;
    color: green;
    width: 100%;
    margin: auto 5px;
}

.blinkButton {
    text-align: center;
    /* line-height: 50px; */
    /* animation: blinkText 1s linear infinite; */
    /* background-color: #004A7F; */
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    /* color: #FFFFFF; */
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
    0% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 1px #B20000;
    }

    50% {
        background-color: #FF0000;
        -webkit-box-shadow: 0 0 3px #FF0000;
    }

    100% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 1px #B20000;
    }
}

@-moz-keyframes glowing {
    0% {
        /* background-color: #B20000; */
        -moz-box-shadow: 0 0 1px #B20000;
    }

    50% {
        /* background-color: #FF0000; */
        -moz-box-shadow: 0 0 3px #FF0000;
    }

    100% {
        /* background-color: #B20000; */
        -moz-box-shadow: 0 0 1px #B20000;
    }
}

@-o-keyframes glowing {
    0% {
        /* background-color: #B20000; */
        box-shadow: 0 0 1px #B20000;
    }

    50% {
        /* background-color: #FF0000; */
        box-shadow: 0 0 3px #FF0000;
    }

    100% {
        background-color: #B20000;
        box-shadow: 0 0 1px #B20000;
    }
}

@keyframes glowing {
    0% {
        /* background-color: #B20000; */
        box-shadow: 0 0 1px #B20000;
    }

    50% {
        /* background-color: #FF0000; */
        box-shadow: 0 0 3px #FF0000;
    }

    100% {
        /* background-color: #B20000; */
        box-shadow: 0 0 1px #B20000;
    }
}

@keyframes blinkText {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}